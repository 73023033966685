import React from "react";
import arrow from "../../../assets/application/arrow-copy.svg";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import "./style.scss";

const Arrow = () => {
  return (
    <div className="services-arrow-img">
      <img src={arrow} alt="arrow" />
    </div>
  );
};

export default function Hero({ data, title }) {
  return (
    <section className="services-banner">
      <div className="container">
        <RichText render={data.hero_title} />
        <div className="services-banner-content">
          <div className="services-banner-image">
            <img src={data.image.url} alt="iam" />
          </div>
          <div className="services-banner-explanation">
            <div className="dot banner-dot-one"></div>
            <img src={data.icon.url} alt="" />
            <RichText render={data.paragraph_one} />
            <RichText render={data.paragraph_two} />
            <div className="service-btn">
              <span>CONTACT US</span>{" "}
              <Link to="/company/contact">
                <Arrow />
              </Link>
            </div>
            <div className="dot banner-dot-two"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
