import React from "react";
import Layout from "../../components/layout";
import SecondaryFooter from "../../components/layout/secondaryFooter";
import Feature from "../../components/services/securityDesign/features";
import { graphql } from "gatsby";
import Hero from "../../components/services/hero";

export default function SecurityDesignManagement(props) {
  if (!props.data) return null;
  const data =
    props.data.allPrismicServicesSecurityDesign.edges[0].node.dataRaw;
  return (
    <Layout>
      <Hero data={data} />
      <Feature data={data.body[0]} />
      <SecondaryFooter />
    </Layout>
  );
}

export const services_security = graphql`
  query services_security {
    allPrismicServicesSecurityDesign {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
